/*Fonts Start*/

/*Roboto*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
/*Poppins*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/*Bebas Neue*/
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
/*Montserrat*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/*Fonts End*/

/*404 Start*/
:root {
  animation: bg 1.5s alternate ease-in-out infinite;
}

.Err404 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* background: url("./assets/Construction.png"),
    linear-gradient(80deg, #0099ff, #005288); */
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 100px;
  flex-wrap: wrap;
}

.ErrCaption {
  color: #5f1d1d;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-family: "Poppins";
  width: 500px;
}

.ErrCaption h1 {
  font-size: 60px;
  margin: 0;
}
.ErrCaption h3 {
  font-size: 36px;
  margin: 0;
  color: #e85555;
}

.ErrCaption p {
  font-size: 18px;
  margin: 0;
  color: #a83030;
}

.Err404 .Images {
  width: 800px;
  height: 600px;
  position: relative;
}

.Err404 .Images img {
  width: 800px;
  height: 600px;
  position: absolute;
}

.Err404 .Images .warning {
  transform-origin: 50% 15%;
  animation: pendulum 1.5s alternate ease-in-out infinite;
}

@keyframes pendulum {
  0% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(10deg);
  }
}

@keyframes bg {
  0% {
    background-color: #fff;
  }
  25% {
    background-color: #eacccc;
  }
  50% {
    background-color: #cc8a8a;
  }
  75% {
    background-color: #eacccc;
  }
  100% {
    background-color: #fff;
  }
}

@media (max-width: 800px) {
  .ErrCaption {
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .Err404 .Images {
    width: 600px;
    height: 450px;
  }

  .Err404 .Images img {
    width: 600px;
    height: 450px;
  }
}

@media (max-width: 600px) {
  .ErrCaption {
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .Err404 .Images {
    width: 400px;
    height: 350px;
  }

  .Err404 .Images img {
    width: 400px;
    height: 350px;
  }
}

@media (max-width: 450px) {
  .ErrCaption {
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .Err404 .Images {
    width: 300px;
    height: 250px;
  }

  .Err404 .Images img {
    width: 300px;
    height: 250px;
  }
}
/*404 End*/
