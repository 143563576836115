@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/*Fonts Start*/

/*Roboto*/
/*Poppins*/
/*Bebas Neue*/
/*Montserrat*/

/*Fonts End*/

/*404 Start*/
:root {
  -webkit-animation: bg 1.5s alternate ease-in-out infinite;
          animation: bg 1.5s alternate ease-in-out infinite;
}

.Err404 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  /* background: url("./assets/Construction.png"),
    linear-gradient(80deg, #0099ff, #005288); */
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 100px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.ErrCaption {
  color: #5f1d1d;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  font-family: "Poppins";
  width: 500px;
}

.ErrCaption h1 {
  font-size: 60px;
  margin: 0;
}
.ErrCaption h3 {
  font-size: 36px;
  margin: 0;
  color: #e85555;
}

.ErrCaption p {
  font-size: 18px;
  margin: 0;
  color: #a83030;
}

.Err404 .Images {
  width: 800px;
  height: 600px;
  position: relative;
}

.Err404 .Images img {
  width: 800px;
  height: 600px;
  position: absolute;
}

.Err404 .Images .warning {
  -webkit-transform-origin: 50% 15%;
          transform-origin: 50% 15%;
  -webkit-animation: pendulum 1.5s alternate ease-in-out infinite;
          animation: pendulum 1.5s alternate ease-in-out infinite;
}

@-webkit-keyframes pendulum {
  0% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  50% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
  }
  100% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
}

@keyframes pendulum {
  0% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  50% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
  }
  100% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
}

@-webkit-keyframes bg {
  0% {
    background-color: #fff;
  }
  25% {
    background-color: #eacccc;
  }
  50% {
    background-color: #cc8a8a;
  }
  75% {
    background-color: #eacccc;
  }
  100% {
    background-color: #fff;
  }
}

@keyframes bg {
  0% {
    background-color: #fff;
  }
  25% {
    background-color: #eacccc;
  }
  50% {
    background-color: #cc8a8a;
  }
  75% {
    background-color: #eacccc;
  }
  100% {
    background-color: #fff;
  }
}

@media (max-width: 800px) {
  .ErrCaption {
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
  }

  .Err404 .Images {
    width: 600px;
    height: 450px;
  }

  .Err404 .Images img {
    width: 600px;
    height: 450px;
  }
}

@media (max-width: 600px) {
  .ErrCaption {
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
  }

  .Err404 .Images {
    width: 400px;
    height: 350px;
  }

  .Err404 .Images img {
    width: 400px;
    height: 350px;
  }
}

@media (max-width: 450px) {
  .ErrCaption {
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
  }

  .Err404 .Images {
    width: 300px;
    height: 250px;
  }

  .Err404 .Images img {
    width: 300px;
    height: 250px;
  }
}
/*404 End*/

